import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { v4 as uuid } from 'uuid';
import { environment } from 'src/frontend/environment/environment';

@Injectable({
  providedIn: 'root',
})
export class HttpWrapperService {
  private defaultHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Request-ID': uuid(),
    ApiKey: `${environment.apiKey}`
  });

  constructor(private http: HttpClient, private oidcSecurityService: OidcSecurityService) {}

  get<T>(url: string, params?: HttpParams | { [param: string]: string | string[] }, language?: string): Observable<T> {
    return this.getHeaders(language).pipe(
      take(1),
      switchMap((headers) => {
        const httpParams = params instanceof HttpParams ? params : new HttpParams({ fromObject: params });
        return this.http.get<T>(url, { headers, params: httpParams });
      }),
    );
  }

  getBlob(url: string, params?: HttpParams | { [param: string]: string | string[] }, language?: string): Observable<HttpResponse<Blob>> {
    return this.getHeaders(language).pipe(
      take(1),
      switchMap((headers) => {
        headers = headers.set('Accept', 'application/pdf');
        headers = headers.set('Content-Type', 'application/pdf');

        const httpParams = params instanceof HttpParams ? params : new HttpParams({ fromObject: params });

        return this.http.get(url, {
          headers,
          params: httpParams,
          responseType: 'blob',
          observe: 'response',
        });
      }),
    );
  }

  post<T>(url: string, body: any): Observable<T> {
    return this.getHeaders().pipe(
      take(1),
      switchMap((headers) => {
        return this.http.post<T>(url, body, { headers });
      }),
    );
  }

  delete<T>(url: string, params?: HttpParams | { [param: string]: string | string[] }, language?: string): Observable<T> {
    return this.getHeaders(language).pipe(
      take(1),
      switchMap((headers) => {
        const httpParams = params instanceof HttpParams ? params : new HttpParams({ fromObject: params });
        return this.http.delete<T>(url, { headers, params: httpParams });
      }),
    );
  }

  getHeaders(language?: string): Observable<HttpHeaders> {
    return this.oidcSecurityService.getAccessToken().pipe(
      take(1),
      map((token) => {
        let headers = this.defaultHeaders.set('Authorization', `Bearer ${token}`);
        if (language) {
          headers = headers.append('Accept-Language', language);
        }
        return headers;
      }),
    );
  }
}