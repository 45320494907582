<p-card>
    <ng-template pTemplate="header">
        <div
            [ngClass]="[
                (brandNameString | lowercase) + '-background-light-color',
                (brandNameString | lowercase) + '-border-color'
            ]"
            class="border-bottom-2"
        >
            <h5 [ngClass]="(brandNameString | lowercase) + '-text-second-color'" class="m-0">{{ 'Your Information' | translate }}</h5>
        </div>
    </ng-template>
    <div *ngIf="memberDetails" class="flex align-items-center">
        <div class="col-6 flex flex-column align-items-start gap-4">
            <div>{{ 'First Name' | translate }}</div>
            <div>{{ 'Last Name' | translate }}</div>
            <div>{{ 'Permanent Address' | translate }}</div>
            <div>{{ 'Phone Number' | translate }}</div>
            <div>{{ 'Email' | translate }}</div>
        </div>
        <div class="col-6 flex flex-column gap-4 font-semibold">
            <div>{{ memberDetails.firstName }}</div>
            <div>{{ memberDetails.lastName }}</div>
            <div>{{ memberDetails.streetAddress }}, {{ memberDetails.zipCode }} {{ memberDetails.city }}</div>
            <div>{{ memberDetails.phoneNumber }}</div>
            <div>{{ memberDetails.email }}</div>
        </div>
    </div>
</p-card>
