<div class="grid">
    <div class="col-12">
        <app-page-header *ngIf="pageHeaderModel" [pageHeaderModel]="pageHeaderModel"></app-page-header>
    </div>
    <div class="col-12 flex flex-column">
        <div *ngFor="let entry of displayedNewsEntries; index as i">
            <div class="card p-0 border-none shadow-2 mb-4">
                <div class="p-card-body p-0 h-full">
                    <div class="p-card-content bg-gray-200 flex p-2 h-full">
                        <div class="md:col-6 p-0" [ngClass]="{ 'hide-image': isScreenSmall }">
                            <img
                                class="w-full"
                                style="object-fit: cover; height: 30vh"
                                [src]="entry.imageUrl"
                                alt="{{ entry.imageAlt }}"
                            />
                        </div>
                        <div
                            class="flex flex-column justify-content-between px-4"
                        >
                            <div class="flex flex-column">
                                <div class="font-semibold text-base mb-2">
                                    {{ entry.date }}
                                </div>
                                <div class="font-semibold text-lg mb-2">
                                    {{ entry.title }}
                                </div>
                                <div class="font-normal text-base mb-2">
                                    {{ entry?.shortDescription ? (entry.shortDescription.length > 300 ? (entry.shortDescription | slice:0:300) + '...' : entry.shortDescription) : '' }}
                                </div>
                            </div>

                            <div class="flex justify-content-between mt-2">
                                <p-button
                                    label="{{ 'button.readMore' | translate }}"
                                    icon="pi fa-solid fa-angle-right ml-2"
                                    (onClick)="navigateToLink(entry.link)"
                                    class="p-button-outlined"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="newsEntries.length > 3 && !showAll" class="col-12 text-center mt-4 uppercase">
            <p-button
                label="{{ 'button.showMore' | translate }}"
                (click)="toggleShowAll()"
                styleClass="p-button-outlined"
                icon="pi fa-solid fa-angle-down"
            >
            </p-button>
        </div>
        <div *ngIf="showAll" class="col-12 text-center mt-4 uppercase">
            <p-button
                label="{{ 'button.showLess' | translate }}"
                (click)="toggleShowAll()"
                styleClass="p-button-outlined"
                icon="pi fa-solid fa-angle-up"
            >
            </p-button>
        </div>
        <!-- <div class="col-12 mt-5"> TODO in next phase
            <h1 class="text-3xl uppercase mb-1 underline" [ngClass]="(brandNameString | lowercase) + '-text-color'">
                {{ 'button.mostPopular' | translate }}
            </h1>
        </div>
        <div class="col-12 flex flex-column sm:flex-row gap-4">
            <div *ngFor="let entry of mostPopularNewsEntries; index as i" class="flex-1">
                <div class="card p-0 border-0 shadow-2 mb-4 h-auto" style="height: 50vh">
                    <div class="p-card-body p-0 h-30rem">
                        <div class="p-card-content bg-gray-200 flex flex-column p-2 h-full">
                            <img
                                class="w-full"
                                style="object-fit: cover; height: 20vh"
                                [src]="entry.imageUrl"
                                alt="{{ 'bannerImageAlt' | translate }}"
                            />
                            <div
                                class="flex flex-column justify-content-between p-4 h-full"
                                [ngClass]="(brandNameString | lowercase) + '-text-color'"
                            >
                                <div class="flex flex-column">
                                    <div class="font-semibold text-base mb-2">
                                        {{ entry.date }}
                                    </div>
                                    <div class="font-semibold text-lg mb-2">
                                        {{ entry.title }}
                                    </div>
                                </div>
                                <div>
                                    <p-button
                                        label="{{ 'button.readMore' | translate }}"
                                        icon="pi fa-solid fa-angle-right"
                                    >
                                    </p-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
    </div>
</div>
