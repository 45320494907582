<div class="card p-2 border-none shadow-3">
    <a href="{{ quickLink.url }}" target="{{ quickLink.target }}">
        <div class="grid grid-no-bottom-padding">
            <div class="col-10">
                <div class="flex align-items-top mt-2 ml-2">
                    <!-- Left in case we want to use Font Awesome Icon Finder or other icon finder in Umbraco -->
                    <!-- <i
                        class="text-3xl mr-2 p-1"
                        [ngClass]="[
                            'pi ' + (quickLinkCardModel.titleIcon | lowercase),
                            (brandNameString | lowercase) + '-text-color'
                        ]"
                    ></i> -->
                    <div class="w-3rem h-3rem pr-1" *ngIf="quickLink.icon">
                        <img src="{{ quickLink.icon }}" alt="" class="max-w-full" />
                    </div>

                    <h3 class="uppercase px-2 mr-3 mb-0 mt-1" [ngClass]="(brandNameString | lowercase) + '-main-color'">
                        {{ quickLink.title }}
                    </h3>
                </div>

                <p class="m-2">{{ quickLink.description }}</p>
            </div>

            <div class="col-2 left-edge-shadow p-0">
                <div class="h-full flex align-content-center flex-wrap">
                    <i
                        class="pi fa-solid fa-chevron-right text-3xl mx-auto px-2"
                        [ngClass]="(brandNameString | lowercase) + '-text-color'"
                    ></i>
                </div>
            </div>
        </div>
    </a>
</div>
