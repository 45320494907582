<p-card>
    <ng-template pTemplate="header">
        <div
            [ngClass]="[
                (brandNameString | lowercase) + '-background-light-color',
                (brandNameString | lowercase) + '-border-color'
            ]"
            class="border-bottom-2"
        >
            <h5 [ngClass]="(brandNameString | lowercase) + '-text-second-color'" class="m-0">{{ 'Maintenance Reservation' | translate }}</h5>
        </div>
    </ng-template>

    <form [formGroup]="bookForm" *ngIf="!formSent">
        <div class="flex flex-column text-sm mb-3">
            <app-select-field
                name="registrationNumber"
                formControlName="registrationNumber"
                [options]="memberCarsRegistrationNumbers"
                [required]="true"
            />
        </div>

        <div class="text-sm mb-3">
            <app-date-field name="serviceProposedDate" formControlName="serviceProposedDate" [required]="true" />
        </div>

        <div class="text-sm mb-3">
            <app-select-field
                name="serviceUrgency"
                formControlName="serviceUrgency"
                [options]="urgencyOptions"
                [required]="true"
            />
        </div>

        <div class="text-sm mb-3">
            <app-number-field name="mileage" formControlName="mileage" [labelTop]="true" [required]="true" />
        </div>

        <div class="text-sm mb-3">
            <app-select-field
                name="contactBy"
                formControlName="contactBy"
                [options]="contactMethodOptions"
                [required]="true"
            />
        </div>

        <div class="text-sm mb-3">
            <app-text-area-field
                name="serviceRequestDescription"
                formControlName="serviceRequestDescription"
                [labelTop]="true"
                [required]="true"
            />
        </div>

        <div class="mb-3">
            <span class="p-input-icon-left w-12">
                <p>{{ 'Select a Service Point' | translate }}</p>

                <app-auto-complete-field
                    [style]="{ height: '40px', width: '100%' }"
                    [suggestions]="filteredDealers"
                    (completeMethod)="searchDealers($event.query)"
                    (onSelect)="selectDealer($event)"
                    field="displayName"
                    placeholder="{{ 'Search by city or name' | translate }}"
                >
                </app-auto-complete-field>
            </span>
        </div>

        <div *ngIf="chosenServiceLocation">
            <h5>{{ 'Selected Service Point' | translate }}:</h5>
            <h6>{{ chosenServiceLocation.displayName }}</h6>
            <p>
                {{ chosenServiceLocation.streetAddress }}<br />
                {{ chosenServiceLocation.postalCode }}<br />
                {{ chosenServiceLocation.city }}<br />
                {{ chosenServiceLocation.phone }}<br />
                {{ chosenServiceLocation.email }}
            </p>
        </div>

        <div class="my-3">
            <p-button
                name="bookServiceSendRequest"
                label="{{ 'Submit Service Request' | translate }}"
                icon="pi pi-save"
                (onClick)="onSendClicked()"
            />
        </div>
    </form>
    <p *ngIf="formSent">{{ 'Book service request sent' | translate }}</p>
</p-card>
<p-toast />
