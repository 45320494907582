import { isDevMode, importProvidersFrom, APP_INITIALIZER, Injector, enableProdMode } from '@angular/core';
import { withInterceptorsFromDi, provideHttpClient, HttpClient } from '@angular/common/http';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { ServiceWorkerModule } from '@angular/service-worker';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
    PreloadAllModules,
    provideRouter,
    withComponentInputBinding,
    withInMemoryScrolling,
    withPreloading,
} from '@angular/router';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { LogLevel, OpenIdConfiguration, provideAuth } from 'angular-auth-oidc-client';
import localeFinnish from '@angular/common/locales/fi';
import { LOCATION_INITIALIZED, registerLocaleData } from '@angular/common';
import { switchMap } from 'rxjs';
import { take } from 'rxjs/operators';
import { ConfirmationService, MessageService, PrimeNGConfig } from 'primeng/api';

import { APP_ROUTES } from './app/app.routes';
import { AuthenticatedGuard } from './app/framework/guards/authenticated.guard';
import { AppComponent } from './app/app.component';
import { UmbracoTranslateLoader } from './app/application/umbraco-translate-loader';
import { ContentService } from './app/application/services/content.service';
import { AuthConfigService } from './app/framework/services/authconfig.service';
import { BrandUrlService } from './app/application/services/brandurl.service';
import { environment } from './environment/environment';

export function initializeApp(translateService: TranslateService, injector: Injector, config: PrimeNGConfig) {
    return () =>
        new Promise<any>((resolve: any) => {
            const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
            locationInitialized.then(() => {
                // TODO: fetch this from url path? .fi/.lv etc?
                const langToSet = 'fi-FI';
                translateService.setDefaultLang(langToSet);

                translateService
                    .use(langToSet)
                    .pipe(switchMap(() => translateService.get('primeng')))
                    .pipe(take(1))
                    .subscribe({
                        next: (res) => {
                            config.setTranslation(res);
                            registerLocaleData(localeFinnish);
                            console.info(`Successfully initialized '${langToSet}' language`);
                        },
                        error: (err) => {
                            console.error(`Problem with '${langToSet}' language initialization`);
                        },
                        complete: () => {
                            resolve(null);
                        },
                    });
            });
        });
}

export function initializeAuthConfig(authConfigService: AuthConfigService): () => Promise<void> {
    return () =>
        new Promise<void>((resolve) => {
            authConfigService.loadAuthority();
            resolve();
        });
}

export function authFactory(authConfigService: AuthConfigService): OpenIdConfiguration {
    return {
        authority: authConfigService.authority!,
        redirectUrl: `${window.location.origin}/auth/callback`,
        postLogoutRedirectUri: `${window.location.origin}/signup`,
        clientId: 'umbraco-member',
        scope: 'openid',
        responseType: 'code',
        silentRenew: true,
        useRefreshToken: true,
        renewTimeBeforeTokenExpiresInSeconds: 30,
        logLevel: LogLevel.Debug,
        historyCleanupOff: true,
        autoUserInfo: false,
    };
}

if (environment.production) {
    enableProdMode();
  }

bootstrapApplication(AppComponent, {
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: initializeApp,
            multi: true,
            deps: [TranslateService, Injector, PrimeNGConfig],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: initializeAuthConfig,
            deps: [AuthConfigService],
            multi: true,
        },
        importProvidersFrom(
            BrowserModule,
            ServiceWorkerModule.register('ngsw-worker.js', {
                enabled: !isDevMode(),
                registrationStrategy: 'registerWhenStable:30000',
            }),
            TranslateModule.forRoot({
                loader: {
                    provide: TranslateLoader,
                    useClass: UmbracoTranslateLoader,
                    deps: [HttpClient, ContentService],
                },
                defaultLanguage: 'fi-FI',
            }),
        ),
        provideAuth({
            config: authFactory(new AuthConfigService(new BrandUrlService)),
        }),
        provideRouter(
            APP_ROUTES,
            withInMemoryScrolling({
                scrollPositionRestoration: 'enabled',
                anchorScrolling: 'enabled',
            }),
            withPreloading(PreloadAllModules),
            withComponentInputBinding(),
        ),
        MessageService,
        ConfirmationService,
        AuthenticatedGuard,
        provideAnimations(),
        provideHttpClient(withInterceptorsFromDi()),
    ],
}).catch((err) => console.error(err));
