import { Component, Input, OnInit } from '@angular/core';
import { QuickLinkCardModel } from '../../models/quickLinkCardModel';
import { BrandService } from '../../services/brand.service';
import { NgClass, LowerCasePipe, CommonModule } from '@angular/common';

@Component({
    selector: 'app-quick-link-card',
    templateUrl: './quick-link-card.component.html',
    standalone: true,
    imports: [NgClass, LowerCasePipe, CommonModule],
})
export class QuickLinkCardComponent implements OnInit {
    @Input({ required: true }) quickLink!: QuickLinkCardModel;

    constructor(public brandService: BrandService) {}

    get brandNameString(): string {
        return this.brandService.brandNameString;
    }

    ngOnInit() {}
}
