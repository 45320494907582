import { NewsEntry } from '../../../frontend/app/application/models/newsEntryModel';
import { ArticleResponse } from '../../../common/models/umbraco-responses/ArticleResponse';
import { DashboardResponse } from '../../../common/models/umbraco-responses/DashboardResponse';
import { DashboardModel } from '../../../frontend/app/application/models/dashboardModel';
import { PageHeaderModel } from '../../../frontend/app/application/models/PageHeaderModel';
import { CarouselProduct } from '../../../frontend/app/application/models/CarouselProduct';
import { ProductDisplayCarouselItem } from '../../../common/models/umbraco-responses/ProductDisplayCarouselItem';
import { ArticleModel } from './models/articleModel';
import { SubpageLinkResponse } from '../../../common/models/umbraco-responses/SubpageLinkResponse';
import { QuickLinkCardModel } from './models/quickLinkCardModel';
import { Injectable } from '@angular/core';
import { HelperService } from '../framework/services/helper.service';
import { SettingsResponse } from '../../../common/models/umbraco-responses/SettingsResponse';
import { SettingsModel } from './models/SettingsModel';
import { EXTERNAL_URL } from 'src/common/constants';
import { LinkItem } from 'src/common/models/umbraco-responses/LinkItem';
import { BrandUrlService } from './services/brandurl.service';

@Injectable({
    providedIn: 'root',
})
export class ContentProcessor {
    constructor(
        public helperService: HelperService,
        public brandUrlService: BrandUrlService,
    ) {}

    getNewsEntriesFromArticleResponse(articleResponse: ArticleResponse[]): NewsEntry[] {
        const newsEntries: NewsEntry[] = [];
        articleResponse.forEach((article) => {
            newsEntries.push({
                id: article.id,
                title: article.properties.title,
                date: article.properties.publishDate
                    ? this.helperService.formatDate(new Date(article.properties.publishDate))
                    : this.helperService.formatDate(new Date(article.createDate)),
                shortDescription: article.properties.shortDescription,
                link: `/articles/${article.id}`,
                imageUrl:
                    article.properties.mainImage && article.properties.mainImage[0]
                        ? `${this.brandUrlService.brandBackendUrl}${article.properties.mainImage[0].url}`
                        : '',
                imageAlt:
                    article.properties.mainImage && article.properties.mainImage[0]
                        ? article.properties.mainImage[0].altText
                        : '',
            });
        });

        return this.sortByDateDesc(newsEntries);
    }

    getArticleFromArticleResponse(articleResponse: ArticleResponse): ArticleModel {
        const article: ArticleModel = {
            id: articleResponse.id,
            title: articleResponse.properties.title,
            publishDate: articleResponse.properties.publishDate
                ? this.helperService.formatDate(new Date(articleResponse.properties.publishDate))
                : this.helperService.formatDate(new Date(articleResponse.updateDate)),
            shortDescription: articleResponse.properties.shortDescription,
            articleBody: articleResponse.properties.articleBody.markup,
            mainImage: {
                url:
                    articleResponse.properties.mainImage && articleResponse.properties.mainImage[0]
                        ? `${this.brandUrlService.brandBackendUrl}${articleResponse.properties.mainImage[0].url}`
                        : '',
                altText:
                    articleResponse.properties.mainImage && articleResponse.properties.mainImage[0]
                        ? articleResponse.properties.mainImage[0].altText
                        : '',
            },
            category: articleResponse.properties.category,
        };

        return article;
    }

    getSettingsFromSettingsResponse(settingsResponse: SettingsResponse): SettingsModel {
        const brandLogo = settingsResponse.properties.brandLogo ? settingsResponse.properties.brandLogo : [];
        const brandMaintenanceUrl = settingsResponse.properties.brandMaintenanceUrl
            ? settingsResponse.properties.brandMaintenanceUrl
            : [];
        const socialLinks = settingsResponse.properties.socialLinks ? settingsResponse.properties.socialLinks : [];
        const settings: SettingsModel = {
            id: settingsResponse.id,
            manualsPage: settingsResponse.properties.manualsPage[0],
            offersPage: settingsResponse.properties.offersPage[0],
            brandLogo: brandLogo.map((logo) => ({
                url: `${this.brandUrlService.brandBackendUrl}${logo.url}`,
                altText: logo.name,
                mediaType: logo.mediaType,
            })),
            brandName: settingsResponse.properties.brandName,
            brandMaintenanceUrl: brandMaintenanceUrl.map((link) => ({
                url: link.url,
                title: link.title,
                target: link.target,
            })),
            socialLinks: socialLinks.map((link) => ({
                url: link.url,
                title: link.title,
                target: link.target,
            })),
        };

        return settings;
    }

    processDashboardContent(dashboardResponse: DashboardResponse): DashboardModel {
        const dashboardModel: DashboardModel = {
            pageHeaderModel: this.getPageHeaderFromResult(dashboardResponse.properties),
            carouselProducts: dashboardResponse.properties.productDisplayCarousel
                ? this.getProductCarouselFromResult(dashboardResponse.properties.productDisplayCarousel.items)
                : [],
            carouselAutorotate: dashboardResponse.properties.carouselAutorotate,
            quickCardLinks: dashboardResponse.properties.subpageLinks
                ? this.getSubpageLinksFromResult(dashboardResponse.properties.subpageLinks.items)
                : [],
        };

        return dashboardModel;
    }

    getPageHeaderFromResult(properties: any): PageHeaderModel {
        const pageHeaderModel: PageHeaderModel = {
            title: properties.title,
            description: properties.description,
        };

        if (properties.heroBanner && properties.heroBanner[0]) {
            pageHeaderModel.imageUrl = `${this.brandUrlService.brandBackendUrl}${properties.heroBanner[0].url}`;
            pageHeaderModel.imageAlt = properties.heroBanner[0].altText;
        }

        return pageHeaderModel;
    }

    private getProductCarouselFromResult(productDisplayCarouselItems: ProductDisplayCarouselItem[]): CarouselProduct[] {
        const carouselProducts: CarouselProduct[] = [];
        productDisplayCarouselItems.forEach((carouselItem) => {
            const linkItem = carouselItem.content.properties.link?.[0];
            const url = linkItem ? this.getLinkUrl(linkItem) : '';
            const carouselProduct: CarouselProduct = {
                id: carouselItem.content.id,
                title: carouselItem.content.properties.title,
                description: carouselItem.content.properties.description,
                link: linkItem
                    ? {
                          url: `${url}`,
                          title: linkItem.title,
                          target: linkItem.target,
                      }
                    : undefined,
                image: {
                    url: `${this.brandUrlService.brandBackendUrl}${carouselItem.content.properties.picture[0].url}`,
                    altText: carouselItem.content.properties.picture[0].altText,
                },
            };

            carouselProducts.push(carouselProduct);
        });

        return carouselProducts;
    }

    private getSubpageLinksFromResult(subpageLinksItems: SubpageLinkResponse[]): QuickLinkCardModel[] {
        const quickLinks: QuickLinkCardModel[] = [];
        subpageLinksItems.forEach((subpageLinksItem) => {
            const linkItem = subpageLinksItem.content.properties.link[0];
            const url = this.getLinkUrl(linkItem);
            const iconUrl = subpageLinksItem.content.properties.icon && subpageLinksItem.content.properties.icon[0]
            ? `${this.brandUrlService.brandBackendUrl}${subpageLinksItem.content.properties.icon[0].url}`
            : '';
            const quickLink: QuickLinkCardModel = {
                id: subpageLinksItem.content.id,
                title: subpageLinksItem.content.properties.title,
                icon: iconUrl,
                description: subpageLinksItem.content.properties.description,
                url: `${url}`,
                target: subpageLinksItem.content.properties.link[0].target,
            };
            quickLinks.push(quickLink);
        });

        return quickLinks;
    }

    private getLinkUrl(linkItem: LinkItem): string {
        return linkItem.linkType === EXTERNAL_URL ? linkItem.url : linkItem.route?.path || '';
    }

    private sortByDateDesc(array: NewsEntry[]) {
        return array.sort((a, b) => this.parseDate(b.date).getTime() - this.parseDate(a.date).getTime());
    }

    private parseDate(dateStr: string): Date {
        if (dateStr.includes('.')) {
            const [day, month, year] = dateStr.split('.').map(Number);
            return new Date(year, month - 1, day);
        } else if (dateStr.includes('/')) {
            const [month, day, year] = dateStr.split('/').map(Number);
            return new Date(year, month - 1, day);
        } else {
            throw new Error(`Unrecognized date format: ${dateStr}`);
        }
    }
}
