<div class="card border-none">
    <div class="flex justify-content-between align-items-center">
        <h4 [ngClass]="(brandNameString | lowercase) + '-text-color'" class="font-bold uppercase">
            {{ 'Latest news' | translate }}
        </h4>
        <a
            [ngClass]="(brandNameString | lowercase) + '-text-color'"
            class="font-bold uppercase"
            href="{{ latestNewsUrl }}"
            >{{ 'See all news' | translate }}<i class="pi fa-solid fa-arrow-right ml-2"></i
        ></a>
    </div>
    <ul class="list-none p-0" [ngClass]="(brandNameString | lowercase) + '-background-light-color'">
        <li *ngFor="let entry of newsEntries; index as i">
            <div class="pt-3 px-3">
                <a
                    href="{{ entry.link }}"
                    class="flex justify-content-between align-items-center pb-3"
                    [ngClass]="[i !== newsEntries.length - 1 ? 'border-bottom-1' : '',
                    (brandNameString | lowercase) + '-link-color']"
                    ><span>{{ entry.title }}</span
                    ><span class="ml-4">{{ entry.date }}</span></a
                >
            </div>
        </li>
    </ul>
</div>
