import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Meta, Title } from '@angular/platform-browser';

import { webManiFest } from '../../../manifest';
import { ContentService } from './content.service';
import { take } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { BrandSettings } from '../../../../common/models/brand-settings';
import { ReplaySubject, BehaviorSubject, Subject } from 'rxjs';
import { LoginConfiguration } from '../../../../common/models/login-configuration';
import { BrandResponse } from '../../../../common/models/umbraco-responses/BrandResponse';
import { HelperService } from '../../framework/services/helper.service';
import { BrandProperties } from 'src/common/models/umbraco-responses/BrandProperties';
import { BrandUrlService } from './brandurl.service';
import { SeoProperties } from 'src/common/models/umbraco-responses/SeoProperties';

@Injectable({
    providedIn: 'root',
})
export class BrandService {
    primaryColor = '';
    brandSettings$ = new ReplaySubject<BrandSettings>(1);
    brandSettings?: BrandSettings;
    loginBackgroundPhotos$: Subject<string[]> = new ReplaySubject<string[]>(1);
    brandId$ = new BehaviorSubject('');

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private title: Title,
        private meta: Meta,
        private contentService: ContentService,
        private helperService: HelperService,
        private brandUrlService: BrandUrlService,
    ) {
        this.getBrandColors();
        this.changeTheme(`theme-${this.brandNameString.toLowerCase()}.css`, 'light');
        this.getBrandId();
        this.getBrandingSettings();

        this.brandSettings$.subscribe((settings) => {
            this.brandSettings = settings;
        });
    }

    get bannerPhoto(): string {
        if (window.location.hostname.indexOf('dacia') > -1) {
            return 'assets/images/dacia-banner.jpg';
        } else if (window.location.hostname.indexOf('renault') > -1) {
            return 'assets/images/renault-banner.jpg';
        } else {
            return 'assets/images/hyundai-banner.webp';
        }
    }

    get brandName(): { brandName: string } {
        if (window.location.hostname.indexOf('dacia') > -1) {
            return { brandName: 'Dacia' };
        } else if (window.location.hostname.indexOf('renault') > -1) {
            return { brandName: 'Renault' };
        } else {
            return { brandName: 'Hyundai' };
        }
    }

    get brandNameString(): string {
        if (window.location.hostname.indexOf('dacia') > -1) {
            return 'Dacia';
        } else if (window.location.hostname.indexOf('renault') > -1) {
            return 'Renault';
        } else {
            return 'Hyundai';
        }
    }

    get loginBackgroundPhotos(): string[] {
        this.brandSettings$.subscribe({
            next: (brandSettings) => {},
        });
        if (window.location.hostname.indexOf('dacia') > -1) {
            return ['duster.jpg', 'jogger.jpg', 'sandero.jpg', 'sandero_stepway.jpg'];
        } else if (window.location.hostname.indexOf('renault') > -1) {
            return ['etech_electric.webp', 'clio.jpg', 'austral.jpg', 'espace.jpg'];
        } else {
            return ['ioniq5n.jpg', 'ioniq6.webp', 'kona-hybrid.jpg', 'tucson.webp'];
        }
    }

    getBrandLogo(): string | null {
        if (!this.brandSettings) {
            return null;
        }

        return `${this.brandUrlService.brandBackendUrl}${this.brandSettings.brandLogoDark?.[0]?.url}` || null;
    }

    get brandColor(): string {
        if (window.location.hostname.indexOf('dacia') > -1) {
            return '#000000';
        } else if (window.location.hostname.indexOf('renault') > -1) {
            return '#000000';
        } else {
            return this.primaryColor;
        }
    }

    changeTheme(theme: string, colorScheme: string) {
        const themeLink = <HTMLLinkElement>this.document.getElementById('theme-css');
        const newHref = themeLink.getAttribute('href')!.replace('theme.css', theme);

        this.replaceThemeLink(newHref);
        this.addFaviconLink();
        this.setThemeColor();
        this.setWebManifest();
    }

    private replaceThemeLink(href: string) {
        const id = 'theme-css';
        const themeLink = <HTMLLinkElement>this.document.getElementById('theme-css');

        const cloneLinkElement = <HTMLLinkElement>themeLink.cloneNode(true);

        cloneLinkElement.setAttribute('href', href);
        cloneLinkElement.setAttribute('id', id + '-clone');

        themeLink.parentNode!.insertBefore(cloneLinkElement, themeLink.nextSibling);

        cloneLinkElement.addEventListener('load', () => {
            themeLink.remove();
            cloneLinkElement.setAttribute('id', id);
        });
    }

    private addFaviconLink() {
        const head = <HTMLLinkElement>this.document.getElementsByTagName('head')[0];
        const faviconLink = <HTMLLinkElement>this.document.createElement('link');
        faviconLink.setAttribute('href', `assets/icons/${this.brandNameString.toLowerCase()}-favicon.ico`);
        faviconLink.setAttribute('type', 'image/x-icon');
        faviconLink.setAttribute('rel', 'icon');
        head.appendChild(faviconLink);
    }

    private setThemeColor() {
        const themeColorLink = <HTMLLinkElement>this.document.getElementById('theme-color');
        themeColorLink.setAttribute('content', `${this.brandColor}`);
    }

    private setWebManifest() {
        const stringManifest = JSON.stringify(webManiFest)
            .replace(new RegExp(/BASE_URL/g), window.location.origin)
            .replace(new RegExp(/BRAND_NAME/g), this.brandNameString.toLowerCase());

        const blob = new Blob([stringManifest], { type: 'application/json' });
        const manifestURL = URL.createObjectURL(blob);
        const manifestLink = this.document.querySelector('#web-manifest');

        if (!manifestLink) {
            return;
        }

        manifestLink.setAttribute('href', manifestURL);
    }

    private getBrandId() {
        const params = new HttpParams({
            fromObject: {
                filter: 'contentType:brand',
            },
        });

        this.contentService
            .getContentItemsFromQuery<{ total: number; items: BrandResponse[] }>(
                params,
                this.helperService.currentLanguage,
            )
            .pipe(take(1))
            .subscribe({
                next: (response) => {
                    const currentBrand = response.items.find(
                        (brandResponse) => brandResponse.name.toLowerCase() === this.brandNameString.toLowerCase(),
                    );

                    if (currentBrand) {
                        this.brandId$.next(currentBrand.id);
                    }
                },
            });
    }

    private getBrandColors() {
        const params = new HttpParams({
            fromObject: {
                filter: 'contentType:brand',
                fields: 'properties[$all]',
            },
        });

        this.contentService
            .getContentItemsFromQuery<{ total: number; items: BrandResponse[] }>(
                params,
                this.helperService.currentLanguage,
            )
            .pipe(take(1))
            .subscribe({
                next: (brandResponse) => {
                    if (!brandResponse || !brandResponse.items || !brandResponse.items.length) {
                        return;
                    }

                    const matchedBrand = brandResponse.items.find(
                        (item) => item.name.toLowerCase() === this.brandNameString.toLowerCase(),
                    );

                    if (!matchedBrand) {
                        return;
                    }
                    this.handleBrandProperties(matchedBrand.properties);
                },
                error: (err) => {
                    console.error('Error fetching brand colors:', err);
                },
            });
    }

    private handleBrandProperties(properties: BrandProperties): void {
        const {
            seo,
            primaryColor,
            secondaryColor,
            lighterColor,
            buttonHoveringBackgroundColor,
            buttonHoveringTextColor,
        } = properties;

        this.applyDynamicCssVariables({
            primaryColor: primaryColor ?? '#002c5f',
            secondaryColor: secondaryColor ?? '#2160aa',
            lighterColor: lighterColor ?? '#e9f1f7',
            buttonHoveringBackgroundColor: buttonHoveringBackgroundColor ?? '#000000',
            buttonHoveringTextColor: buttonHoveringTextColor ?? '#ffffff',
        });

        if (seo) {
            this.setSeoTags(seo);
        }
    }

    private applyDynamicCssVariables(props: {
        primaryColor?: string;
        secondaryColor?: string;
        lighterColor?: string;
        buttonHoveringBackgroundColor?: string;
        buttonHoveringTextColor?: string;
    }): void {
        const rootStyle = this.document.documentElement.style;

        if (props.primaryColor) {
            rootStyle.setProperty('--brand-primary', props.primaryColor);
            rootStyle.setProperty('--primary-color', props.primaryColor);
        }
        if (props.secondaryColor) {
            rootStyle.setProperty('--brand-secondary', props.secondaryColor);
        }
        if (props.lighterColor) {
            rootStyle.setProperty('--brand-lighter', props.lighterColor);
        }
        if (props.buttonHoveringBackgroundColor) {
            rootStyle.setProperty('--brand-button-background-color', props.buttonHoveringBackgroundColor);
        }
        if (props.buttonHoveringTextColor) {
            rootStyle.setProperty('--brand-button-text-color', props.buttonHoveringTextColor);
        }
    }

    private setSeoTags(seoProperties: SeoProperties) {
        if (seoProperties.title) {
            this.title.setTitle(seoProperties.title);
        }
        if (seoProperties.description) {
            this.meta.updateTag({ name: 'description', content: seoProperties.description });
        }
        if (seoProperties.noIndex) {
            this.meta.updateTag({ name: 'robots', content: 'noindex, nofollow' });
        }
    }

    private getBrandingSettings() {
        const params = new HttpParams({
            fromObject: {
                filter: 'contentType:auth',
                fields: 'properties[$all]',
            },
        });

        this.contentService
            .getContentItemsFromQuery<{ total: number; items: LoginConfiguration[] }>(
                params,
                this.helperService.currentLanguage,
            )
            .pipe(take(1))
            .subscribe({
                next: (loginConfigurationResponse) => {
                    const root = loginConfigurationResponse.items[0];

                    if (!root) {
                        return;
                    }

                    const loginConfigurationItems = root.properties.authConfiguration.items;
                    const loginConfiguration = loginConfigurationItems.find(
                        (item) => item.content.properties.brand[0].name === this.brandNameString,
                    );

                    if (!loginConfiguration) {
                        return;
                    }

                    this.brandSettings$.next(loginConfiguration.content.properties);

                    if (!loginConfiguration.content.properties.brandBackgroundImages) {
                        this.loginBackgroundPhotos$.next([]);
                        return;
                    }

                    this.loginBackgroundPhotos$.next(
                        loginConfiguration.content.properties.brandBackgroundImages.map((image) => image.url),
                    );
                },
            });
    }
}
