import { Injectable } from '@angular/core';
import { HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpWrapperService } from './http-wrapper.service';
import { BrandUrlService } from './brandurl.service';

@Injectable({
    providedIn: 'root',
})
export class ContentService {
    constructor(
        private httpWrapper: HttpWrapperService,
        private brandUrlService: BrandUrlService
    ) {}

    getContentItemsFromQuery<T>(params: HttpParams, language?: string): Observable<T> {
        const url = `${this.url}umbraco/delivery/api/v2/content`;
        return this.httpWrapper.get<T>(url, params, language);
      }
    
      getContentItemByRoute<T>(path: string, language?: string): Observable<T> {
        const url = `${this.url}umbraco/delivery/api/v2/content/item/${path}`;
        return this.httpWrapper.get<T>(url, undefined, language);
      }

      getMemberById(id: string): Observable<any> {
        const url = `${this.url}umbraco/delivery/api/v2/content/item/${id}`;
        return this.httpWrapper.get<any>(url);
      }
    
      getServiceBookPdf(vin: string): Observable<HttpResponse<Blob>> {
        const url = `${this.url}umbraco/api/MaintenanceApi/ServiceBookPdf?vin=${vin}`;
        return this.httpWrapper.getBlob(url);
      }
    
      get url(): URL {
        return typeof window !== 'undefined' ? new URL(this.brandUrlService.brandBackendUrl) : new URL('http://127.0.0.1:8889'); // tests
      }
}