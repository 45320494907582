import { Component, HostListener, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { BrandService } from '../../services/brand.service';
import { NewsEntry } from '../../models/newsEntryModel';
import { HelperService } from '../../../framework/services/helper.service';
import { ContentService } from '../../services/content.service';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { NgClass, NgFor, NgIf, LowerCasePipe, CommonModule } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { ArticleResponse } from '../../../../../common/models/umbraco-responses/ArticleResponse';
import { ContentProcessor } from '../../content.processor';
import { take } from 'rxjs';
import { PageHeaderComponent } from '../page-header/page-header.component';
import { PageHeaderModel } from '../../models/PageHeaderModel';
import { PageResponse } from '../../../../../common/models/umbraco-responses/PageResponse';

@Component({
    selector: 'app-articles',
    templateUrl: './articles.component.html',
    styleUrl: './articles.component.scss',
    standalone: true,
    imports: [NgClass, NgFor, ButtonModule, NgIf, LowerCasePipe, TranslateModule, CommonModule, PageHeaderComponent],
})
export class ArticlesComponent implements OnInit {
    items!: MenuItem[];
    car: any;
    newsEntries: NewsEntry[] = [];
    mostPopularNewsEntries: NewsEntry[] = [];
    showAll: boolean = false;
    pageHeaderModel!: PageHeaderModel;
    isScreenSmall: boolean = false;

    constructor(
        private brandService: BrandService,
        private contentService: ContentService,
        public helperService: HelperService,
        public contentProcessor: ContentProcessor,
    ) {}

    ngOnInit() {
        this.brandService.brandId$.subscribe({
            next: (brandId) => {
                if (brandId.length) {
                    const articlesParams = new HttpParams({
                        fromObject: {
                            filter: 'contentType:article',
                            fields: 'properties[$all]',
                            take: 4,
                            fetch: `descendants:${brandId}`,
                        },
                    });

                    const pageParams = new HttpParams({
                        fromObject: {
                            filter: 'contentType:articles',
                            fields: 'properties[$all]',
                            fetch: `descendants:${brandId}`,
                        },
                    });

                    this.contentService
                        .getContentItemsFromQuery<{ total: number; items: ArticleResponse[] }>(
                            articlesParams,
                            this.helperService.currentLanguage,
                        )
                        .pipe(take(1))
                        .subscribe({
                            next: (results) => {
                                this.newsEntries = this.contentProcessor.getNewsEntriesFromArticleResponse(
                                    results.items,
                                );
                            },
                        });

                    this.contentService
                        .getContentItemsFromQuery<{ total: number; items: PageResponse[] }>(
                            pageParams,
                            this.helperService.currentLanguage,
                        )
                        .pipe(take(1))
                        .subscribe({
                            next: (results) => {
                                this.pageHeaderModel = this.contentProcessor.getPageHeaderFromResult(
                                    results.items[0].properties,
                                );
                            },
                        });
                }
            },
        });

        this.mostPopularNewsEntries = this.newsEntries.slice(0, 3);
        this.checkScreenWidth();
    }

    get brandNameString(): string {
        return this.brandService.brandNameString;
    }

    get bannerPhoto(): string {
        return this.brandService.bannerPhoto;
    }

    get brandName(): { brandName: string } {
        return this.brandService.brandName;
    }

    toggleShowAll() {
        this.showAll = !this.showAll;
    }

    get displayedNewsEntries() {
        return this.showAll ? this.newsEntries : this.newsEntries.slice(0, 3);
    }

    navigateToLink(link: string): void {
        window.location.href = link;
      }

    @HostListener('window:resize', ['$event'])
    onResize(event: any): void {
      this.checkScreenWidth();
    }
  
    private checkScreenWidth(): void {
      this.isScreenSmall = window.innerWidth < 768;
    }
}
